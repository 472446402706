import { api, IListLadderProfileResponse } from '@features';

import { IProfile } from "./Profile";
import { ILadderMatch } from '../ladderMatch';


export const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUserProfile: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: `/profile/me`,  
        }
      },
      providesTags: ['Profile'],
      transformResponse: (response: { profile: IProfile, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.profile;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getProfilesList: builder.query<IListLadderProfileResponse, { page?: number, limit?: number, sort?: string, search?: string }>({
      query: ({ page = 1, limit = 25, sort, search }) => {
        let url = `/profile?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['Profile'],
      transformResponse: (response: IListLadderProfileResponse, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getUserProfileById: builder.query<IProfile, string>({
      query: (profileId: string) => {
        return {
          method: 'GET',
          url: `/profile/${profileId}`,
        };
      },
      providesTags: ['Profile'],
      transformResponse: (response: { profile: IProfile, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.profile;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    getMyPlayerStats: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: `/profile/me/stats`,
        };
      },
      providesTags: ['Profile'],
      transformResponse: (response: { stats: any, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.stats;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getMyMatchReports: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: `/profile/me/reports`,
        };
      },
      providesTags: ['Profile'],
      transformResponse: (response: { matches: ILadderMatch[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.matches;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    updateCurrentUserProfile: builder.mutation<IProfile, Partial<IProfile>>({
      query: (updatedProfile) => {
        const formattedProfile: any = {...updatedProfile};
        formattedProfile.primaryTeam = formattedProfile.primaryTeam?._id;
        return {
          method: 'PUT',
          url: '/profile/me',
          body: formattedProfile
        }
      },
      invalidatesTags: [{ type: 'Profile' }],
      transformResponse: (response: { profile: IProfile, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.profile;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    })
  }),
});

export const {
  useGetCurrentUserProfileQuery,
  useGetProfilesListQuery,
  useGetMyPlayerStatsQuery,
  useGetUserProfileByIdQuery,
  useGetMyMatchReportsQuery,
  useUpdateCurrentUserProfileMutation
} = profileApi;