import { api, IListLadderMatchResponse } from '@features';

import { ILadderMatch, ILadderMatchResult } from './LadderMatch';

export const ladderMatchesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLadderMatchesByLadderId: builder.query<IListLadderMatchResponse, { ladderId: string, page?: number, limit?: number, sort?: string, search?: string }>({
      query: ({ page = 1, ladderId, limit = 25, sort, search }) => {
        return {
          method: 'GET',
          url: `/ladder/${ladderId}/match`,
        };
      },
      providesTags: ['LadderMatch'],
      transformResponse: (response: IListLadderMatchResponse, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getLadderMatchById: builder.query<ILadderMatch, string>({
      query: (matchId: string) => {
        return {
          method: 'GET',
          url: `/laddermatch/${matchId}`,
        };
      },
      providesTags: ['LadderMatch'],
      transformResponse: (response: { ladderMatch: ILadderMatch, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.ladderMatch;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    listLadderMatches: builder.query<IListLadderMatchResponse,  { page?: number, limit?: number, sort?: string, search?: string }>({
      query: ({ page = 1, limit = 25, sort, search }) => {
        let url = `/laddermatch?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['LadderMatch'],
      transformResponse: (response: IListLadderMatchResponse, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    reportLadderMatchResults: builder.mutation<ILadderMatch, { results: ILadderMatchResult | 'confirm' | 'dispute', matchId: string}>({
      query: ({ results, matchId }) => {
        return {
          method: 'POST',
          url: `/laddermatch/${matchId}/report`,
          body: {
            results
          }
        };
      },
      invalidatesTags: ['LadderMatch'],
      transformResponse: (response: { ladderMatch: ILadderMatch, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.ladderMatch;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
  })
});

export const { useGetLadderMatchesByLadderIdQuery, useGetLadderMatchByIdQuery, useReportLadderMatchResultsMutation, useListLadderMatchesQuery } = ladderMatchesApi;