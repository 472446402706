import { api } from '@features';

import { ILadder } from "./Ladder";
import { ILadderChallenge } from '../ladderChallenge/LadderChallenge'

export const ladderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createLadder: builder.mutation<ILadder, Partial<ILadder>>({
      query: (newLadder: ILadder) => {
        return {
          method: 'POST',
          url: `/ladder`,
          body: newLadder,
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [{ type: 'Ladder' }],
      transformResponse: (response: { ladder: ILadder, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.ladder;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response, meta, arg) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    deleteLadderById: builder.mutation<boolean, string>({
      query: (ladderId: string) => {
        return {
          method: 'DELETE',
          url: `/ladder/${ladderId}`,
        }
      },
      invalidatesTags: [{ type: 'Ladder' }],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response, meta, arg) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    getLadders: builder.query<ILadder[], { page?: number; limit?: number; populate?: string, search?: string; sort?: string; }>({
      query: ({ limit = 1000, page = 1, populate, search, sort }) => {
        let url = `/ladder?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        if (populate) url += `&populate=${populate}`;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: result => {
        if (result) {
          return [
            ...Object.values(result).map(ladder => ({ type: "Ladder" as const, _id: ladder?._id, name: ladder?.name })),
            { type: "Ladder", _id: "LIST", name: "LIST" },
          ];
        }
        return [{ type: "Ladder", ladder: "LIST", name: "LIST" }];
      },
      transformResponse: (response: { ladders: ILadder[], success: boolean }, meta, arg) => {
        return response.ladders;
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getLadder: builder.query<ILadder, string>({
      query: (ladderId: string) => {
        return {
          method: 'GET',
          url: `/ladder/${ladderId}`,
        };
      },
      providesTags: (_result, _err, _id) => [{ type: 'Ladder', _id }],
      transformResponse: (response: { ladder: ILadder, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.ladder;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    signupToLadder: builder.mutation({
      query: ({ ladderId, competitorId }) => {
        return {
          method: 'POST',
          url: `/ladder/${ladderId}`,
          body: { competitor: competitorId },
        }
      },
      invalidatesTags: ['Ladder'],
      transformResponse: (response: { ladders: ILadder[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.ladders;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    sendLadderChallenge: builder.mutation<ILadderChallenge, any>({
      query: ({
        ladderId,
        challenger,
        challengee,
        matchTime,
        message,
        teamChallengingPick,
        teamChallengingPreferenceOne,
        teamChallengingPreferenceTwo,
        teamChallengingPreferenceThree        
      }) => {
        return {
          method: 'POST',
          url: `/ladder/${ladderId}/challenge?challenger=${challenger}&challengee=${challengee}`,
          body: {
            matchTime,
            message,
            teamChallengingPick,
            teamChallengingPreferenceOne,
            teamChallengingPreferenceTwo,
            teamChallengingPreferenceThree
          },
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          }
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ['Challenge'],
      transformResponse: (response: { challenge: ILadderChallenge, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.challenge;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    updateLadderById: builder.mutation<ILadder, {ladder: Partial<ILadder>; ladderId: string}>({
      query: ({ladder, ladderId }) => {
        return {
          method: 'PUT',
          url: `/ladder/${ladderId}`,
          body: ladder,
        }  
      },
      invalidatesTags: ['Ladder'],
      transformResponse: (response: { ladder: ILadder, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.ladder;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    })
  }),
});

export const {
  useCreateLadderMutation,
  useDeleteLadderByIdMutation,
  useGetLaddersQuery,
  useGetLadderQuery,
  useSendLadderChallengeMutation,
  useSignupToLadderMutation,
  useUpdateLadderByIdMutation
} = ladderApi;