import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslate, useTolgee } from "@tolgee/react";

import { NavbarDropdownButton } from '@components';
import logoBrand from './Midair2MainLogo.svg';
//import creditsLogo from './credits.svg';
import { EUserRole, logoutUser, useGetLaddersQuery } from '@features';
import type { ILadder, INotification } from '@features';
import { useAppDispatch } from '@store/hooks';

const isProd = process.env.NODE_ENV === 'production';
const showHidden = false;
type NavBarLink = {
  header?: string;
  title: string;
  link: string;
}

interface INavbar {
  /** If the user is currently logged in */
  loggedIn: boolean;

  /** The currently Logged in user or null if not logged in */
  user: any | null;

  notifications: INotification[];
}

const Navbar = ({
  loggedIn,
  user,
  notifications,
}: INavbar) => {
  const tolgee = useTolgee(['language']);
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // @ts-ignore
  const { data: ladders } = useGetLaddersQuery({ limit: 1000, populate: 'entries,' });
  const [moderatorLadders, setModeratorLadders] = useState<ILadder[]>([]);
  const [className, setClassName] = useState('collapse navbar-collapse');
  const languages = [{
    name: 'English',
    key: 'en'
  }, {
    name: 'Spanish',
    key: 'es',
  },  {
    name: 'French',
    key: 'fr'
  }, {
    name: 'German',
    key: 'de'
  }, {
    name: 'Japanese',
    key: 'ja'
  }, {
    name: 'Korean',
    key: 'ko'
  }]

  useEffect(() => {
    if (ladders && loggedIn && user) {
      setModeratorLadders(ladders.filter((ladder: ILadder) => ladder.mods && ladder.mods.includes(user._id)));
    }
  }, [ladders, loggedIn, user]);


  const openPopup = () => {
    const popupWindow = window.open(isProd ? 'https://api.midair2.gg/v1/auth/steam' : 'http://localhost:9000/v1/auth/steam',
      "_blank",
      "width=800, height=600",
    );
    // @ts-ignore
    if (window.focus) popupWindow.focus();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark   navbar-ma fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logoBrand} style={{ width: '97px', maxHeight: '92px' }} width="97" alt="logo-brand" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarElement" aria-controls="navbarElement" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setClassName(className.includes('show') ? 'collapse navbar-collapse' : 'navbar-collapse collapse show') }>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={className} id="navbarElement">
        <ul className="navbar-nav mr-auto ma-nav">
          <NavbarDropdownButton
              title={t('navbar.Game')}
              buttons={[
                {
                  sections: [{
                    title: t('navbar.About') || '',
                    link: '/about'
                  }, {
                    title: t('navbar.Modes') || '',
                    link: '/modes'                        
                  }, {
                    title: t('navbar.Roadmap') || '',
                    link: '/roadmap'                        
                  },/* {
                    title: t('navbar.Team') || '',
                    link: '/team'                        
                  }, */{
                    title: t('navbar.Media') || '',
                    link: '/media'                        
                  }, {
                    title: 'Streams',
                    link: '/streams'                        
                  }, {
                    title: t('navbar.Maps') || '',
                    link: '/maps'                      
                  }, {
                    title: t('navbar.Privacy', 'Privacy Policy') || '',
                    link: '/privacy' 
                  }, {
                    title: t('navbar.terms', 'Terms of Service') || '',
                    link: '/tos'
                  }]
                }
              ]}
            />
          <li className="nav-item">
            <Link to="/news" className="nav-link">{t('navbar.News')}</Link>
          </li>
          {<li className="nav-item">
            <Link to="/servers" className="nav-link">{t('navbar.Servers').toUpperCase()}</Link>
            </li>}
          { showHidden && (
            <NavbarDropdownButton
              title={t('navbar.Competitive')}
              buttons={[
                {
                  sections: (ladders && ladders.length) ? ladders.map((ladder: ILadder, index: number) => {
                    const section: NavBarLink = {
                      title: ladder.name,
                      link: `/ladders/${ladder.name}`                        
                    }
                    if (index === 0) {
                      section.header = t('navbar.Ladders') || '';
                    }
                    return section;
                  }) : [{
                    header: t('navbar.Ladders') || '',
                  }]
                },
                {
                  sections: [{
                    title: t('navbar.CompetitivePlayers') || '',
                    link: '/players  '                 
                  }, {
                    title: t('navbar.CompetitiveTeams') || '',
                    link: '/teams'                        
                  },
                  {
                    title: t('navbar.CompetitiveCreateTeam') || '',
                    link: '/teams/create'                      
                  }]
                }
              ]}
            />
          )}
          {/*<li className="nav-item">
            <Link to="/shop" className="nav-link">{t('navbar.Shop')}</Link>
        </li>*/}
          <li className="nav-item">
            <Link to="/donate" className="nav-link">DONATE</Link>
          </li>
        </ul>
        <ul className="navbar-nav ma-nav" style={className.includes('show') ? {} :{ alignItems: 'center' }}>
          { loggedIn && user && user.role === EUserRole.ServerAdmin && (
            <NavbarDropdownButton
              title={'ADMIN'}
              buttons={[
                {
                  sections: [{
                    header: 'Servers',
                    title: 'Server List',
                    link: '/admin/servers'
                  }, {
                    title: 'Create Server',
                    link: '/admin/servers/create'
                  }]
                }
              ]}
            />
          )}
          { loggedIn && user && user.role === EUserRole.Developer && (
            <NavbarDropdownButton
              title={'ADMIN'}
              buttons={[
                {
                  sections: [{
                    header: 'Servers',
                    title: 'Server List',
                    link: '/admin/servers'
                  }, {
                    title: 'Audit',
                    link: '/admin/servers/audit'
                  }, {
                    title: 'Create Server',
                    link: '/admin/servers/create'
                  }, {
                    title: 'Default Settings',
                    link: '/admin/servers/settings'
                  }]
                },
                {
                  sections: [{
                    header: 'Users',
                    title: 'User List',
                    link: '/admin/users'
                  }]
                },
                {
                  sections: [{
                    header: 'Items',
                    title: 'List Items',
                    link: '/admin/items',
                  }, {
                    title: 'Import Items',
                    link: '/admin/items/import',                    
                  }]
                },
                {
                  sections: [{
                    header: 'Maps',
                    title: 'Map List',
                    link: '/admin/maps'
                  }, {
                    title: 'Create Map',
                    link: '/admin/maps/create'
                  }]
                },
                {
                  sections: [{
                    header: 'Gamelift',
                    title: 'Info',
                    link: '/admin/gamelift'
                  }]
                },
                {
                  sections: [{
                    header: 'Vouchers',
                    title: 'Voucher List',
                    link: '/admin/vouchers'
                  }, {
                    title: 'Create Vouchers',
                    link: '/admin/vouchers/create'
                  }]
                },
                {
                  sections: [{
                    header: 'Matches',
                    title: 'Match List',
                    link: '/admin/matches'
                  }]
                },
                {
                  sections: [{
                    header: 'Orders',
                    title: 'Order List',
                    link: '/admin/orders'
                  }]
                },
                {
                  sections: [{
                    header: 'Blog',
                    title: 'Blog List',
                    link: '/admin/blog/list'
                  }, {
                    title: 'New Blog Post',
                    link: '/admin/blog/create'                 
                  }]
                }
              ]}
            />
          )}
          { loggedIn && user && user.role <= EUserRole.Admin && showHidden && (
            <NavbarDropdownButton
              title={'LADDER ADMIN'}
              buttons={[
                {
                  sections: [{
                    title: 'Manager Ladders',
                    link: '/ladders/manage'
                  }]
                },
                {
                  sections: [{
                    title: 'Ladder Matches',
                    link: '/ladders/manage/matches'
                  }, {
                    title: 'Match Disputes',
                    link: '/ladders/manage/disputes',                    
                  }, {
                    title: 'Match List',
                    link: '/ladders/matches',
                  }, {
                    title: 'Challenges',
                    link: '/challenges',                    
                  }]
                },
                {
                  sections: [{
                    title: 'All Players',
                    link: '/laders/players'                 
                  }, {
                    title: 'All Teams',
                    link: '/laders/teams'                       
                  }]
                }
              ]}
            />
          )}
          { (moderatorLadders.length && user.role > EUserRole.Admin) ? (
            <NavbarDropdownButton
              title={'LADDER ADMIN'}
              buttons={
                moderatorLadders.map((ladder) => ({
                  sections: [{
                    title: `Manager ${ladder.name}`,
                    link: `/ladders/manage/${ladder._id}`
                  }]
                }))
              }
            />
          ) : null}
          {/* loggedIn && user && (
            <NavbarDropdownButton
              title={user.credits}
              image={creditsLogo}
              imagePosition={'before'}
              buttons={[
                {
                  sections: [{
                    title: t('navbar.ShopBuyCredits') || '',
                    link: '/credits'
                  }, {
                    title: t('navbar.ShopRedeem') || '',
                    link: '/redeem'
                  }]
                }
              ]}
            />      
            )*/}
          { !loggedIn ? (
            <li className="nav-item">
              <button type="button" className="nav-link" style={{ backgroundColor: 'transparent', border: 'none' }} onClick={openPopup}>{t('navbar.login').toUpperCase()}</button>
            </li>
          ) : user && notifications && (
            <NavbarDropdownButton
              title={user.displayName}
              image={user.avatar}
              showNotification={notifications.filter((notification) => notification.status === 0).length ? true : false}
              notificationCount={notifications.filter((notification) => notification.status === 0).length}
              buttons={[
                {
                  sections: [/*{
                    title: t('navbar.ProfileDashboard') || '',
                    link: '/dashboard'
                  },*/{
                    title: t('navbar.ProfileMyProfile') || '',
                    link: '/user/profile'
                  },/* {
                    title: t('common.editProfile') || '',
                    link: '/user/profile/edit'
                  }, {
                    title: t('navbar.ProfileNotifications') || '',
                    link: '/user/notifications',
                  }, {
                    title: 'My Servers',
                    link: '/user/servers'
                  }, {
                    title: t('navbar.ProfileMyReports') || '',
                    link: '/user/reports',
                  }, */{
                    title: t('navbar.ProfileInventory') || '',
                    link: '/user/inventory',
                  },/*{
                    title: 'My Servers',
                    link: '/servers'
                  }, */{
                    title: t('navbar.ProfileOrderHistory') || '',
                    link: '/orders/history'                    
                  }, {
                    title: t('navbar.ProfileMatchHistory') || '',
                    link: '/user/matches'                 
                  }]
                },
                {
                  sections: [{
                    title: t('navbar.logout') || '',
                    fn: () => {
                      dispatch(logoutUser());
                      navigate('/', { replace: true });
                    }
                  }]
                }
              ]}
            />
          )}
          {languages && (
            <NavbarDropdownButton
              title={languages.find((l) => l.key === tolgee.getLanguage())?.name || ''}
              buttons={[
                {
                  sections: Object.keys(languages).map((lng, idx) => {
                    // @ts-ignore
                    return { title: languages[lng].name, fn: () => tolgee.changeLanguage(languages[lng].key), key: idx }
                  })
                }
              ]}
            />
          )}
        </ul>
      </div>
      </div>
    </nav>
  );
}

export default Navbar;