import React, { useEffect, useState } from 'react';

interface ITabbedPage {
  /** List of page names */
  pageName: string;

  Content: () => JSX.Element;
}

interface ITabbedPages {
  pages: ITabbedPage[];
  pageClicked?: (pageName: string) => void;
}

const TabbedPages = ({
  pages,
  pageClicked,
}: ITabbedPages) => {
  const [ activePage, setActivePage ] = useState('');

  useEffect(() => {
    setActivePage(pages[0].pageName);
  }, []);

  const clickPage = (pageName: string) => {
    if (pageClicked) {
      pageClicked(pageName);
    }
    setActivePage(pageName)
  }
 
  return (
    <div className="section">
      <ul className="nav nav-tabs">
        {pages.map((page, idx) => {
          return (
            <li className="nav-item" role="presentation" key={idx}>
              <a className={`nav-link ${activePage === page.pageName ? 'active' : ''}`} href="#teams" aria-controls="teams" role="tab" data-toggle="tab" aria-selected="true" onClick={() => clickPage(page.pageName)}>
                <span>{page.pageName}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab-content">
        {pages.map((page, idx) => activePage === page.pageName ? <page.Content key={idx}/> : null) }
      </div>
    </div>
  );
}

export default TabbedPages;