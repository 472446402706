import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes as Router } from 'react-router-dom';
import { LoadingBarRef } from 'react-top-loading-bar';

import { AuthLayout, useAuth } from "../store/hooks";

const BlogPostPage = lazy(() => import(/* webpackChunkName: "BlogPostPage" */ /* webpackPrefetch: true */ '../pages/BlogPostPage'));

const DiscordPage = lazy(() => import(/* webpackChunkName: "DiscordPage" */ /* webpackPrefetch: true */ '../pages/DiscordPage'));
const SteamPage = lazy(() => import(/* webpackChunkName: "SteamPage" */ /* webpackPrefetch: true */ '../pages/SteamPage'));

const CreateNewMapPage = lazy(() => import(/* webpackChunkName: "CreateNewMapPage" */ /* webpackPrefetch: true */ '../pages/admin/maps/CreateNewMapPage'));
const EditMapPage = lazy(() => import(/* webpackChunkName: "EditMapPage" */ /* webpackPrefetch: true */ '../pages/admin/maps/EditMapPage'));
const ListMapsPage = lazy(() => import(/* webpackChunkName: "ListMapsPage" */ /* webpackPrefetch: true */ '../pages/admin/maps/ListMapsPage'));
const ListBlogPostsPage = lazy(() => import(/* webpackChunkName: "ListBlogPostsPage" */ /* webpackPrefetch: true */ '../pages/admin/blog/ListBlogPostsPage'));
const EditBlogPostPage = lazy(() => import(/* webpackChunkName: "EditBlogPostPage" */ /* webpackPrefetch: true */ '../pages/admin/blog/EditBlogPostPage'));

// Admin Items
const AdminListItemsPage = lazy(() => import(/* webpackChunkName: "AdminListItemsPage" */ /* webpackPrefetch: true */ '../pages/admin/item/AdminListItemsPage'));
const ImportItemsPage = lazy(() => import(/* webpackChunkName: "ImportItemsPage" */ /* webpackPrefetch: true */ '../pages/admin/item/ImportItemsPage'));

const AdminAuditServersPage = lazy(() => import(/* webpackChunkName: "AdminAuditServersPage" */ /* webpackPrefetch: true */ '../pages/admin/servers/AdminAuditServersPage'));
const AdminCreateServersPage = lazy(() => import(/* webpackChunkName: "AdminCreateServersPage" */ /* webpackPrefetch: true */ '../pages/admin/servers/AdminCreateServersPage'));
const AdminListServersPage = lazy(() => import(/* webpackChunkName: "AdminListServersPage" */ /* webpackPrefetch: true */ '../pages/admin/servers/AdminListServersPage'));
const AdminManageServersPage = lazy(() => import(/* webpackChunkName: "AdminManageServersPage" */ /* webpackPrefetch: true */ '../pages/admin/servers/AdminManageServersPage'));
const AdminDefaultServerSettingsPage = lazy(() => import(/* webpackChunkName: "AdminDefaultServerSettingsPage" */ /* webpackPrefetch: true */ '../pages/admin/servers/AdminDefaultServerSettingsPage'));

const AdminListMatchesPage = lazy(() => import(/* webpackChunkName: "AdminListMatchesPage" */ /* webpackPrefetch: true */ '../pages/admin/matches/AdminListMatchesPage'));
const AdminManageMatchPage = lazy(() => import(/* webpackChunkName: "AdminManageMatchPage" */ /* webpackPrefetch: true */ '../pages/admin/matches/AdminManageMatchPage'));

const AdminListOrdersPage = lazy(() => import(/* webpackChunkName: "AdminListOrdersPage" */ /* webpackPrefetch: true */ '../pages/admin/orders/AdminListOrdersPage'));
const AdminManageOrdersPage = lazy(() => import(/* webpackChunkName: "AdminManageOrdersPage" */ /* webpackPrefetch: true */ '../pages/admin/orders/AdminManageOrdersPage'));

const AdminListUsersPage = lazy(() => import(/* webpackChunkName: "AdminListUsersPage" */ /* webpackPrefetch: true */ '../pages/admin/users/AdminListUsersPage'));
const AdminManageUserPage = lazy(() => import(/* webpackChunkName: "AdminManageUserPage" */ /* webpackPrefetch: true */ '../pages/admin/users/AdminManageUserPage'));

const AdminGameliftInfoPage = lazy(() => import(/* webpackChunkName: "AdminGameliftInfoPage" */ /* webpackPrefetch: true */ '../pages/admin/gamelift/GameliftInfoPage'));
const BlogNewPostPage = lazy(() => import(/* webpackChunkName: "BlogNewPostPage" */ /* webpackPrefetch: true */ '../pages/admin/blog/BlogNewPostPage'));
//const CreditsPage = lazy(() => import(/* webpackChunkName: "CreditsPage" */ /* webpackPrefetch: true */ '../pages/CreditsPage'));
//const DashboardPage = lazy(() => import(/* webpackChunkName: "DashboardPage" */ /* webpackPrefetch: true */ '../pages/DashboardPage'));
const DonatePage = lazy(() => import(/* webpackChunkName: "DonatePage" */ /* webpackPrefetch: true */ '../pages/DonatePage'));
const AboutPage = lazy(() => import(/* webpackChunkName: "AboutPage" */ /* webpackPrefetch: true */ '../pages/AboutPage'));
const HomePage = lazy(() => import(/* webpackChunkName: "HomePage" */ /* webpackPrefetch: true */ '../pages/HomePage'));
const MapsPage = lazy(() => import(/* webpackChunkName: "MapsPage" */ /* webpackPrefetch: true */ '../pages/MapsPage'));
//const TeamPage = lazy(() => import(/* webpackChunkName: "TeamPage" */ /* webpackPrefetch: true */ '../pages/TeamPage'));
const RoadmapPage = lazy(() => import(/* webpackChunkName: "RoadmapPage" */ /* webpackPrefetch: true */ '../pages/RoadmapPage'));
const MediaPage = lazy(() => import(/* webpackChunkName: "MediaPage" */ /* webpackPrefetch: true */ '../pages/MediaPage'));
const ModesPage = lazy(() => import(/* webpackChunkName: "ModesPage" */ /* webpackPrefetch: true */ '../pages/ModesPage'));
const NewsPage= lazy(() => import(/* webpackChunkName: "NewsPage" */ /* webpackPrefetch: true */ '../pages/NewsPage'));
const PurchaseCompletePage= lazy(() => import(/* webpackChunkName: "PurchaseCompletePage" */ /* webpackPrefetch: true */ '../pages/PurchaseCompletePage'));
const RedeemPage = lazy(() => import(/* webpackChunkName: "RedeemPage" */ /* webpackPrefetch: true */ '../pages/RedeemPage'));
const ServersPage = lazy(() => import(/* webpackChunkName: "ServersPage" */ /* webpackPrefetch: true */ '../pages/ServersPage'));
//const ShopPage = lazy(() => import(/* webpackChunkName: "ShopPage" */ /* webpackPrefetch: true */ '../pages/ShopPage'));
//const ShopItemPage = lazy(() => import(/* webpackChunkName: "ShopItemPage" */ /* webpackPrefetch: true */ '../pages/ShopItemPage'));
const UserMatchHistoryPage = lazy(() => import(/* webpackChunkName: "UserMatchHistoryPage" */ /* webpackPrefetch: true */ '../pages/user/UserMatchHistoryPage'));
const VouchersCreatePage = lazy(() => import(/* webpackChunkName: "VouchersCreatePage" */ /* webpackPrefetch: true */ '../pages/admin/vouchers/VouchersCreatePage'));
const VouchersListPage = lazy(() => import(/* webpackChunkName: "VouchersListPage" */ /* webpackPrefetch: true */ '../pages/admin/vouchers/VouchersListPage'));

// Orders
const OrderHistoryPage = lazy(() => import(/* webpackChunkName: "OrderHistoryPage" */ /* webpackPrefetch: true */ '../pages/orders/OrderHistoryPage'));

// Ladder 
//const CreateTeamPage = lazy(() => import(/* webpackChunkName: "CreateTeamPage" */ /* webpackPrefetch: true */ '../pages/ladders/CreateTeamPage'));
//const EditTeamPage = lazy(() => import(/* webpackChunkName: "EditTeamPage" */ /* webpackPrefetch: true */ '../pages/EditTeamPage'));
//const ManagerLadderPage = lazy(() => import(/* webpackChunkName: "ManagerLadderPage" */ /* webpackPrefetch: true */ '../pages/ladders/ManagerLadderPage'));
//const ManageLaddersPage = lazy(() => import(/* webpackChunkName: "ManageLaddersPage" */ /* webpackPrefetch: true */ '../pages/ladders/ManageLaddersPage'));
//const LadderChallengePage = lazy(() => import(/* webpackChunkName: "LadderChallengePage" */ /* webpackPrefetch: true */ '../pages/ladders/LadderChallengePage'));
//const ListDisputesPage = lazy(() => import(/* webpackChunkName: "ListDisputesPage" */ /* webpackPrefetch: true */ '../pages/ladders/ListDisputesPage'));
//const ListPlayersPage = lazy(() => import(/* webpackChunkName: "ListPlayersPage" */ /* webpackPrefetch: true */ '../pages/ladders/ListPlayersPage'));
//const ListTeamsPage = lazy(() => import(/* webpackChunkName: "ListTeamsPage" */ /* webpackPrefetch: true */ '../pages/ladders/ListTeamsPage'));
//const UserChallengesPage = lazy(() => import(/* webpackChunkName: "UserChallengesPage" */ /* webpackPrefetch: true */ '../pages/user/UserChallengesPage'));
//const ViewChallengePage = lazy(() => import(/* webpackChunkName: "ViewChallengePage" */ /* webpackPrefetch: true */ '../pages/ladders/ViewChallengePage'));
//const ViewChallengesPage = lazy(() => import(/* webpackChunkName: "ViewChallengesPage" */ /* webpackPrefetch: true */ '../pages/ladders/ViewChallengesPage'));
//const ViewLadderPage = lazy(() => import(/* webpackChunkName: "ViewLadderPage" */ /* webpackPrefetch: true */ '../pages/ladders/ViewLadderPage'));
//const ViewLadderMatchPage = lazy(() => import(/* webpackChunkName: "ViewLadderMatchPage" */ /* webpackPrefetch: true */ '../pages/ladders/ViewLadderMatchPage'));
//const ViewMatchesPage = lazy(() => import(/* webpackChunkName: "ViewTeamPage" */ /* webpackPrefetch: true */ '../pages/matches/ViewMatchesPage'));
const ViewProfilePage = lazy(() => import(/* webpackChunkName: "ViewProfilePage" */ /* webpackPrefetch: true */ '../pages/ladders/ViewProfilePage'));
//const ViewTeamPage = lazy(() => import(/* webpackChunkName: "ViewTeamPage" */ /* webpackPrefetch: true */ '../pages/ViewTeamPage'));

// Ladder Admin
//const ListLadderMatchesPage = lazy(() => import(/* webpackChunkName: "ListLadderMatchesPage" */ /* webpackPrefetch: true */ '../pages/ladders/admin/ListLadderMatchesPage'));

// User
//const EditProfilePage = lazy(() => import(/* webpackChunkName: "EditProfilePage" */ /* webpackPrefetch: true */ '../pages/user/EditProfilePage'));
const UserNotificationsPage = lazy(() => import(/* webpackChunkName: "UserNotificationsPage" */ /* webpackPrefetch: true */ '../pages/user/UserNotificationsPage'));
//const UserInventoryPage = lazy(() => import(/* webpackChunkName: "UserInventoryPage" */ /* webpackPrefetch: true */ '../pages/user/UserInventoryPage'));
//const UserReportsPage = lazy(() => import(/* webpackChunkName: "UserReportsPage" */ /* webpackPrefetch: true */ '../pages/user/UserReportsPage'));

// User Server
//const UserRentServerPage = lazy(() => import(/* webpackChunkName: "UserRentServerPage" */ /* webpackPrefetch: true */ '../pages/user/UserRentServerPage'));
//const UserServersPage = lazy(() => import(/* webpackChunkName: "UserServersPage" */ /* webpackPrefetch: true */ '../pages/user/UserServersPage'));
//const ServerFileExplorerPage = lazy(() => import(/* webpackChunkName: "ServerFileExplorerPage" */ /* webpackPrefetch: true */ '../pages/ServerFileExplorerPage'));

const PrivacyPolicyPage = lazy(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ /* webpackPrefetch: true */ '../pages/PrivacyPolicyPage'));
const StreamsPage = lazy(() => import(/* webpackChunkName: "StreamsPage" */ /* webpackPrefetch: true */ '../pages/StreamsPage'));
const NotFoundPage = lazy(() => import(/* webpackChunkName: "NotFoundPage" */ /* webpackPrefetch: true */ '../pages/NotFoundPage'));
const TOSPage = lazy(() => import(/* webpackChunkName: "TOSPage" */ /* webpackPrefetch: true */ '../pages/TOSPage'));

export const ProtectedRoute = ({ children }: { children?: React.ReactNode }) => {
  const isLoggedIn = useAuth();
  if (!isLoggedIn) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  if (children) return <>{children}</>
  return <Outlet />;
};

interface IRoutes {
  loadingRef: React.RefObject<LoadingBarRef>;
}

export const Routes: React.FC<IRoutes> = (props) => (
  <Suspense fallback={<div></div>}>
    <Router>
      <Route element={<AuthLayout />}>
        <Route path="/about" element={<AboutPage />} />
        <Route path="/admin" element={<ProtectedRoute />}>
          <Route path="blog">
            <Route path="create" element={<BlogNewPostPage />} />
            <Route path="edit/:id" element={<EditBlogPostPage loadingRef={props.loadingRef} />} />
            <Route path="list" element={<ListBlogPostsPage loadingRef={props.loadingRef} />} />
          </Route>
          <Route path="gamelift" element={<AdminGameliftInfoPage />} />
          <Route path="maps">
            <Route path="create" element={<CreateNewMapPage loadingRef={props.loadingRef} />} />
            <Route path=":id" element={<EditMapPage loadingRef={props.loadingRef} />} />
            <Route path="" element={<ListMapsPage loadingRef={props.loadingRef} />} />
          </Route>
          <Route path="matches/:id" element={<AdminManageMatchPage loadingRef={props.loadingRef} />} />
          <Route path="matches" element={<AdminListMatchesPage loadingRef={props.loadingRef} />} />
          <Route path="items/import" element={<ImportItemsPage loadingRef={props.loadingRef} />} />
          <Route path="items" element={<AdminListItemsPage loadingRef={props.loadingRef} />} />
          <Route path="orders/:id" element={<AdminManageOrdersPage />} />
          <Route path="orders" element={<AdminListOrdersPage />} />
          <Route path="servers/audit" element={<AdminAuditServersPage loadingRef={props.loadingRef} />} />
          <Route path="servers/create" element={<AdminCreateServersPage />} />
          <Route path="servers/manage" element={<AdminManageServersPage />} />
          <Route path="servers/settings" element={<AdminDefaultServerSettingsPage loadingRef={props.loadingRef}/>} />
          <Route path="servers" element={<AdminListServersPage  loadingRef={props.loadingRef} />} />
          <Route path="user/:id" element={<AdminManageUserPage />} />
          <Route path="users" element={<AdminListUsersPage />} />
          <Route path="vouchers" element={<VouchersListPage />} />
          <Route path="vouchers/create" element={<VouchersCreatePage loadingRef={props.loadingRef} />} />
        </Route>

        {/*<Route path="/challenges/:id" element={<ViewChallengePage loadingRef={props.loadingRef} />} />
        <Route path="/challenges" element={<ViewChallengesPage loadingRef={props.loadingRef} />} /> */}
        {/*<Route path="/credits" element={<CreditsPage />} />*/}

        {/*<Route path="/dashboard" element={<DashboardPage />} />*/}
        <Route path="/discord" element={<DiscordPage />} />
        <Route path="/donate" element={<DonatePage />} />

        {/*<Route path="/ladders/manage" element={<ProtectedRoute />}>
          <Route path="matches" element={<ListLadderMatchesPage loadingRef={props.loadingRef} />} />
          <Route path="disputes" element={<ListDisputesPage loadingRef={props.loadingRef} />} />
          <Route path=":id" element={<ManagerLadderPage loadingRef={props.loadingRef} />} />
          <Route path="" element={<ManageLaddersPage loadingRef={props.loadingRef} />} />
        </Route>

        <Route path="/ladders/:id/challenge" element={<LadderChallengePage />} />
      <Route path="/ladders/:id" element={<ViewLadderPage loadingRef={props.loadingRef} />} />*/}

        <Route path="/maps" element={<MapsPage loadingRef={props.loadingRef} />} />
        {/*<Route path="/matches" element={<ProtectedRoute />}>
          <Route path=":id" element={<ViewLadderMatchPage loadingRef={props.loadingRef} />} />
          <Route path="" element={<ViewMatchesPage loadingRef={props.loadingRef} />} />
    </Route> */}
        <Route path="/media" element={<MediaPage />} />
        <Route path="/modes" element={<ModesPage />} />

        <Route path="/news" element={<NewsPage />} />

        <Route path="/orders" element={<ProtectedRoute />}>
          <Route path="history" element={<OrderHistoryPage />} />
          <Route path="success" element={<PurchaseCompletePage />} />
        </Route>

        {/*<Route path="/players" element={<ProtectedRoute />}>
          <Route path="challenges" element={<UserChallengesPage />} />
          <Route path=":id" element={<ViewProfilePage loadingRef={props.loadingRef} />} />
          <Route path="" element={<ListPlayersPage loadingRef={props.loadingRef} />} />
  </Route>*/ }
        <Route path="/privacy" element={<PrivacyPolicyPage />} />

        <Route path="/redeem" element={<RedeemPage loadingRef={props.loadingRef} />} />
        <Route path="/roadmap" element={<RoadmapPage />} />

        <Route path="/servers" element={<ServersPage loadingRef={props.loadingRef} />} />
       {/* <Route path="/servers/files"  element={<ProtectedRoute />}>
          <Route path=":id" element={<ServerFileExplorerPage loadingRef={props.loadingRef} />} />
</Route>*/}
        {/*<Route path="/shop" element={<ShopPage loadingRef={props.loadingRef} />} />
        <Route path="/shop/:id" element={<ShopItemPage />} />*/}
        <Route path="/steam" element={<SteamPage />} />
        <Route path="/streams" element={<StreamsPage loadingRef={props.loadingRef} /> } />

				{/*<Route path="/team" element={<TeamPage />} />
        
        <Route path="/teams/create" element={
          <ProtectedRoute>
            <CreateTeamPage loadingRef={props.loadingRef} />
          </ProtectedRoute>
        } />
        <Route path="/teams/view/:id" element={<ViewTeamPage loadingRef={props.loadingRef} />} />
        <Route path="/teams/edit/:id" element={<EditTeamPage loadingRef={props.loadingRef} />} />
      <Route path="/teams" element={<ListTeamsPage />} />*/}

        <Route path="/tos" element={<TOSPage />} />

        <Route path="/user" element={<ProtectedRoute />}>
          {/*<Route path="inventory" element={<UserInventoryPage loadingRef={props.loadingRef}/>} />*/}
          <Route path="matches" element={<UserMatchHistoryPage loadingRef={props.loadingRef}/>} />
          <Route path="notifications" element={<UserNotificationsPage loadingRef={props.loadingRef}/>} />
          {/*<Route path="profile/edit" element={<EditProfilePage loadingRef={props.loadingRef} />} /> */}
          <Route path="profile" element={<ViewProfilePage loadingRef={props.loadingRef} />} />
          {/*<Route path="reports" element={<UserReportsPage loadingRef={props.loadingRef}/>} />
          <Route path="servers/rent" element={<UserRentServerPage loadingRef={props.loadingRef} />} />*/}
          {/*<Route path="servers" element={<UserServersPage loadingRef={props.loadingRef}/> } />*/}
        </Route>
        <Route path="/:id" element={<BlogPostPage loadingRef={props.loadingRef} />} />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      </Router>
  </Suspense>
);
