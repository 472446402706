import { api, IListLadderTeamsResponse } from '@features';

import { ILadderTeam } from "./LadderTeam";

export const ladderTeamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createTeam: builder.mutation<ILadderTeam, Partial<ILadderTeam>>({
      query: (newTeam: ILadderTeam | Partial<ILadderTeam>) => {
        return {
          method: 'POST',
          url: `/team`,
          body: newTeam,
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ['Team'],
      transformResponse: (response: { team: ILadderTeam, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.team;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    deleteTeam: builder.mutation<boolean, string>({
      query: (teamId: string) => {
        return {
          method: 'DELETE',
          url: `/team/${teamId}`,
        }
      },
      invalidatesTags: ['Team'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    editTeam: builder.mutation({
      query: (team: ILadderTeam) => {
        return {
          method: 'PUT',
          url: `/team/${team._id}`,
          body: team
        };
      },
      invalidatesTags: ['Team'],
      transformResponse: (response: { team: ILadderTeam, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.team;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getTeamById: builder.query<ILadderTeam, string>({
      query: (teamId: string) => {
        return {
          method: 'GET',
          url: `/team/${teamId}`,
        };
      },
      providesTags: (_result, _err, _id) => [{ type: 'Team', _id }],
      transformResponse: (response: { team: ILadderTeam, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.team;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getUsersTeams: builder.query<ILadderTeam[], void>({
      query: () => {
        return {
          method: 'GET',
          url: `/team/me`
        };
      },
      providesTags: (_result, _err, _id) => [{ type: 'Team', _id }],
      transformResponse: (response: { teams: ILadderTeam[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.teams;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getTeams: builder.query<IListLadderTeamsResponse, { page?: number, limit?: number, populate?: string; sort?: string, search?: string }>({
      query: ({ page = 1, limit = 25, populate, sort, search }) => {
        let url = `/team?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        if (sort && sort.length) url += `&sort=${sort}`;
        if (populate) url += `&populate=${populate}`;
        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['Team'],
      transformResponse: (response: IListLadderTeamsResponse, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    updateTeamImage: builder.mutation<{ fileName: string; signedRequest: string; url: string }, { fileName: string; fileType: string; teamId: string }>({
      query: ({ fileName, fileType, teamId }) => {
        return {
          method: 'GET',
          url: `/team/${teamId}/image?fileName=${fileName}&fileType=${fileType}`,
        };
      },
      transformResponse: (response: { fileName: string; signedRequest: string; url: string }, meta, arg) => {
        if (response) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
  }),
});

export const {
  useCreateTeamMutation,
  useDeleteTeamMutation,
  useEditTeamMutation,
  useGetTeamByIdQuery,
  useGetTeamsQuery,
  useGetUsersTeamsQuery,
  useUpdateTeamImageMutation
} = ladderTeamApi;