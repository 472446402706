import { DateTime } from 'luxon';

export const dateFromAPIData = (date = new Date().toString()) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
}

export const dateFromMoment = (date: string) => {
  return DateTime.fromJSDate(new Date(date), {zone: "utc"}).toLocaleString(DateTime.DATETIME_SHORT)
}

export const dateFromTimestamp = (date: number) => {
  return DateTime.fromSeconds(date, {zone: "utc"}).toLocaleString(DateTime.DATETIME_SHORT)
}