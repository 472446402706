import { api } from '@features';

import { ILadderTeamMemberInvitation } from './LadderTeamMemberInvitation';

export const invitationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createNewInvitation: builder.mutation<ILadderTeamMemberInvitation, { team: string; ladder: string; user: string; }>({
      query: (invitation: { team: string; ladder: string; user: string; }) => {
        return {
          method: 'POST',
          url: `/invitation`,
          body: invitation
        }
      },
      invalidatesTags: ['Invitation'],
      transformResponse: (response: { invitation: ILadderTeamMemberInvitation, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.invitation;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    respondToInvite: builder.mutation<boolean, { invitationId: string; response: string; }>({
      query: ({
        invitationId,
        response,
      }: { invitationId: string; response: string; }) => {
        return {
          method: 'PUT',
          url: `/invitation/${invitationId}`,
          body: {
            response
          }
        }
      },
      invalidatesTags: ['Invitation'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
  }),
});

export const { useCreateNewInvitationMutation, useRespondToInviteMutation } = invitationApi;