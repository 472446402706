import { ILadder } from "../ladder/Ladder";
import { ILadderMatchComment } from "../ladderMatchComment/LadderMatchComment";
import { IMapPick } from "../mapPick";
import { IProfile } from "../profile";
import { ILadderTeam } from "../ladderTeam";
import { IUser } from "../user";

export interface IMapResult {
  challengingTeam: number;
  teamChallenged: number;
}

export interface ILadderMatchResult {
  firstMap: IMapResult;
  secondMap: IMapResult;
  thirdMap: IMapResult;
}

export enum ELadderMatchCompetitionType {
  'ladders',
  'tournaments',
}

export enum ELadderMatchStatus {
  scheduled,
  reported,
  confirmed,
  disputed,
  tournament_bye,
  undetermined
}

export const getLadderMatchStatus = (status: ELadderMatchStatus): string => {
  switch (status) {
    case ELadderMatchStatus.scheduled:
      return 'Scheduled';

    case ELadderMatchStatus.reported:
      return 'Reported';

    case ELadderMatchStatus.confirmed:
      return 'Confirmed';

    case ELadderMatchStatus.disputed:
      return 'Disputed';

    case ELadderMatchStatus.tournament_bye:
      return 'Bye Round';

    case ELadderMatchStatus.undetermined:
      return 'Undertimined';

    default:
      return '';
  }
}

export const getLoserNameFromMatch = (match: ILadderMatch): string => {
  if (match.competitorModel === 'LadderTeam') {
    return (match.loser as ILadderTeam).name;
  }
  return (match.loser as IProfile).user.displayName || '';
}

export const getChallengingTeamNameFromMatch = (match: ILadderMatch): string => {
  if (match.competitorModel === 'LadderTeam') {
    return (match.challengingTeam as ILadderTeam).name;
  }
  return (match.challengingTeam as IProfile).user.displayName || '';
}

export const getTeamChallengedNameFromMatch = (match: ILadderMatch): string => {
  if (match.competitorModel === 'LadderTeam') {
    return (match.teamChallenged as ILadderTeam).name;
  }
  return (match.teamChallenged as IProfile).user.displayName || '';
}


export const getWinnerNameFromMatch = (match: ILadderMatch): string => {
  if (match.competitorModel === 'LadderTeam') {
    return (match.winner as ILadderTeam).name;
  }
  return (match.winner as IProfile).user.displayName || '';
}


export interface ILadderMatch {
  _id: string;
  /** The id of the ladder this challenge belongs to */
  ladder: ILadder;

  /** The team that issued the challenge */
  competitionType: ELadderMatchCompetitionType;

  /** The team being challenged */
  challengingTeam: ILadderTeam | IProfile;

  /** The team being challenged */
  teamChallenged: ILadderTeam | IProfile;

  competitorModel: 'LadderPlayerProfile' | 'LadderTeam'
  
  /** time of the match */
  matchTime: number;

  /** The state of the challenge */
  matchStatus: ELadderMatchStatus;

  /** Any comments associated with the ladder */
  comments: ILadderMatchComment[];

  /** The id of the match played */
  matchId: string;

  /** The Id of the map picks collection */
  mapPicks: IMapPick;

  reportedBy: IUser;

  /** The expiry time of this challenge */
  expires_at: string; 
  
  results: ILadderMatchResult;
  challengingTeamSubmittedResults: boolean;
  teamChallengedSubmittedResults: boolean;

  winner?: ILadderTeam | IProfile;
  loser?: ILadderTeam | IProfile;

  createdAt: string;
}