import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { ILadderMatch } from './LadderMatch';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const ladderMatchAdapter = createEntityAdapter<ILadderMatch>();
export const initialLadderMatchState = ladderMatchAdapter.getInitialState();

const ladderMatchSlice = createSlice({
  name: 'ladderMatchSlice',
  initialState: initialLadderMatchState,
  reducers: {},
});

export default ladderMatchSlice.reducer;

export const {
  selectAll: selectAllLadderMatchs,
  selectById: selectLadderMatchById,
  selectIds: selectLadderMatchIds
} = ladderMatchAdapter.getSelectors((state: RootState) => state.ladderMatch);