import { api } from '@features';

import { IPayment } from './Payment';

interface ListResponse<T> {
  currentPage: number
  limit: number
  total: number
  totalPages: number
  payments: T[]
}

export const paymentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPayment: builder.mutation({
      query: (bundle: string) => {
        return {
          method: 'POST',
          url: `/payment`,
          body: { bundle }
        }
      },
      invalidatesTags: ['Payment'],
      transformResponse: (response: { success: boolean, url: string }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    createServerPayment: builder.mutation<{url: string; success: boolean; }, void>({
      query: () => {
        return {
          method: 'POST',
          url: `/payment/server`,
        }
      },
      invalidatesTags: ['Payment'],
      transformResponse: (response: { url: string; success: boolean }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getAllPayments: builder.query({
      query: ({ page = 1, limit = 25, search }) => {
        let url = `/payment?page=${page}&limit=${limit}`;
        if (search) url += `&search=${search}`;
        return {
          url,
          method: 'GET',
        }
      },
      providesTags: ['Payment'],
      transformResponse: (response: { payments: IPayment[], success: boolean; totalPages: number, currentPage: number, limit: number, total: number }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getMyPayments: builder.query<ListResponse<IPayment>, number | void>({
      query: (page = 1) => {
        return {
          method: 'GET',
          url: `/payment/me`,
        }
      },
      providesTags: ['Payment'],
      transformResponse: (response: { payments: IPayment[], success: boolean, totalPages: number, currentPage: number, limit: number, total: number }, meta, arg) => {
        if (response.success) {
          return response;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getPaymentById: builder.query({
      query: (paymentId: string) => {
        return {
          url: `/payment/${paymentId}`,
          method: 'GET',
        }
      },
      providesTags: ['Payment'],
      transformResponse: (response: { payment: IPayment, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.payment;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    })
  }),
});

export const { useCreatePaymentMutation, useCreateServerPaymentMutation, useGetAllPaymentsQuery, useGetMyPaymentsQuery, useGetPaymentByIdQuery } = paymentApi;