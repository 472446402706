import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { ILadderChallenge } from './LadderChallenge';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const ladderChallengeAdapter = createEntityAdapter<ILadderChallenge>({
  selectId: (ladderChallenge) => ladderChallenge._id,
});
export const initialLadderChallengeState = ladderChallengeAdapter.getInitialState();

const ladderChallengeSlice = createSlice({
  name: 'ladderChallenge',
  initialState: initialLadderChallengeState,
  reducers: {},
});

export default ladderChallengeSlice.reducer;

export const {
  selectAll: selectAllLadderChallenges,
  selectById: selectLadderChallengeById,
  selectIds: selectLadderChallengeIds
} = ladderChallengeAdapter.getSelectors((state: RootState) => state.ladderChallenge);