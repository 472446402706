import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import BSThemeProvider from 'react-bootstrap/ThemeProvider';
import { TolgeeProvider } from "@tolgee/react";


import App from './App';
import reportWebVitals from './reportWebVitals';
import configureAppStore, { getPreloadedState } from './store/configureStore';

import './custom.scss';
import { ladderApi, ladderTeamApi } from '@features';

import tolgee from './lang';

(async () => {
  const preloadedState = getPreloadedState();
  const root = ReactDOM.createRoot(document.getElementById('root')!);
  const store = configureAppStore(preloadedState);

  store.dispatch(ladderApi.endpoints.getLadders.initiate({ limit: 1000, populate: 'entries,' }));
  store.dispatch(ladderTeamApi.endpoints.getTeams.initiate({ limit: 1000, populate: 'teamMembers,ladderEntries,matches,challenges' }));
  root.render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <TolgeeProvider tolgee={tolgee}>
          <BSThemeProvider breakpoints={['lg']}>
            <ThemeProvider theme={{}}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ThemeProvider>
          </BSThemeProvider>
        </TolgeeProvider>
      </ReduxProvider>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
