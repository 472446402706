import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { ILadderTeamMemberInvitation } from './LadderTeamMemberInvitation';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const ladderTeamMemberInvitationAdapter = createEntityAdapter<ILadderTeamMemberInvitation>({
  selectId: (ladderChallenge) => ladderChallenge._id,
});
export const initialLadderTeamMemberInvitationState = ladderTeamMemberInvitationAdapter.getInitialState();

const ladderTeamMemberInvitationSlice = createSlice({
  name: 'ladderTeamMemberInvitation',
  initialState: initialLadderTeamMemberInvitationState,
  reducers: {},
});

export default ladderTeamMemberInvitationSlice.reducer;

export const {
  selectAll: selectAllLadderTeamMemberInvitations,
  selectById: selectLadderTeamMemberInvitationById,
  selectIds: selectLadderTeamMemberInvitationIds
} = ladderTeamMemberInvitationAdapter.getSelectors((state: RootState) => state.ladderTeamMemberinvitation);