import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from "@tolgee/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { IPayment } from '@features';
import { DataTable, IPaginateOpts } from '@components';
import { dateFromAPIData } from '@services/datetime';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export interface IOrdersTable {
  isAdmin?: boolean;
  payments: IPayment[];
  paginateOpts?: IPaginateOpts;
}

export const OrdersTable = ({
  isAdmin,
  payments,
  paginateOpts,
}: IOrdersTable) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const columns = [
    {
      name: t('common.item'),
      sortable: true,
      selector: (row: IPayment) => `${row.product}`,
    },
    {
      name: t('common.price'),
      sortable: true,
      selector: (row: IPayment) => row.product.includes('Bundle') ? formatter.format(Number(row.amount) / 100) : row.amount,
    },
    {
      name: t('orderHistoryPage.creditsPrior'),
      sortable: true,
      selector: (row: IPayment) => row.creditsPrior,
    },
    {
      name: t('orderHistoryPage.creditsAfter'),
      sortable: true,
      selector: (row: IPayment) => row.creditsAfter,
    },
    {
      name: t('orderHistoryPage.fullfilled'),
      selector: (row: IPayment) => row.fulfilled ? t('common.yes') : t('common.no'),  
      sortable: true,  
    },
    {
      name: t('common.date'),
      selector: (row: IPayment) => dateFromAPIData(row.createdAt.toString()),    
    },
  ];

  const adminColumn = {
    name: 'Manage',
    cell: (row: IPayment) => {
      return (
        <div>
          <button className="btn-link" onClick={() => navigate(`/admin/orders/${row._id}`)}><FontAwesomeIcon icon={faEdit} /></button>&nbsp;
        </div>
      );
    }  
  };

  return (
    <DataTable
      columns={!isAdmin ? columns : [...columns, adminColumn ]}
      data={payments}
      paginateOpts={paginateOpts}
    />
  );
}