import {
  createSelector,
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { ILadderTeam } from './LadderTeam';
import { StoreGetState } from '@store/configureStore';
import { ladderTeamApi } from './ladderTeamApi';

type RootState = ReturnType<StoreGetState>


const ladderTeamAdapter = createEntityAdapter<ILadderTeam>({
  selectId: (ladderTeam) => ladderTeam._id,
});
export const initialLadderTeamState = ladderTeamAdapter.getInitialState();

const ladderTeamSlice = createSlice({
  name: 'ladderTeam',
  initialState: initialLadderTeamState,
  reducers: {},
});

export default ladderTeamSlice.reducer;

export const selectLadderTeamByName = createSelector(
  ladderTeamApi.endpoints.getTeams.select({ limit: 1000, populate: 'teamMembers,ladderEntries,matches,challenges' }),
  (state: RootState, ladderName: string) => ladderName,
  (state, ladderName) => {
    // @ts-ignore
    return state.data?.teams.find((ladder) => ladder.name === ladderName) ?? [];
  }
);

export const selectLadderTeamByNameOrId = createSelector(
  ladderTeamApi.endpoints.getTeams.select({ limit: 1000, populate: 'teamMembers,ladderEntries,matches,challenges' }),
  (state: RootState, ladderNameOrId: string) => ladderNameOrId,
  (state, ladderNameOrId) => {
    let ret: ILadderTeam | undefined = undefined;
    const ladderTeam = state.data?.teams.find((ladder) => ladder.name === ladderNameOrId || ladder._id === ladderNameOrId) ;
    if (ladderTeam) {
      ret = ladderTeam;
    }
    return ret;
  }
);


export const {
  selectAll: selectAllLadderTeams,
  selectById: selectLadderTeamById,
  selectIds: selectLadderTeamIds
} = ladderTeamAdapter.getSelectors((state: RootState) => state.ladderTeam);