import { api } from '@features';

import { ILadderMatch } from '../ladderMatch';

export const ladderMatchCommentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addNewLadderMatchComment: builder.mutation<ILadderMatch, { commentText: string; matchId: string; }>({
      query: ({ commentText, matchId }) => {
        return {
          method: 'POST',
          url: `/laddermatch/${matchId}/comment`,
          body: {
            commentText
          }
        };
      },
      invalidatesTags: ['LadderMatchComment'],
      transformResponse: (response: { ladderMatch: ILadderMatch, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.ladderMatch;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
  })
})

export const { useAddNewLadderMatchCommentMutation } = ladderMatchCommentApi;