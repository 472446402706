import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { ILadderDispute } from './LadderDispute';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const ladderDisputeAdapter = createEntityAdapter<ILadderDispute>({
  selectId: (ladderDispute) => ladderDispute._id,
});
export const initialLadderDisputeState = ladderDisputeAdapter.getInitialState();

const ladderDisputeSlice = createSlice({
  name: 'ladderDispute',
  initialState: initialLadderDisputeState,
  reducers: {},
});

export default ladderDisputeSlice.reducer;

export const {
  selectAll: selectAllLadderDisputes,
  selectById: selectLadderDisputeById,
  selectIds: selectLadderDisputeIds
} = ladderDisputeAdapter.getSelectors((state: RootState) => state.ladderDispute);