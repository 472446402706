import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { IPayment } from './Payment';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const paymentAdapter = createEntityAdapter<IPayment>({
  selectId: (payment) => payment._id,
});
export const initialPaymentState = paymentAdapter.getInitialState();

const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialPaymentState,
  reducers: {},
});

export default paymentSlice.reducer;

export const {
  selectAll: selectAllPayments,
  selectById: selectPaymentById,
  selectIds: selectPaymentIds
} = paymentAdapter.getSelectors((state: RootState) => state.payment);