import {
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { ILadderMatchComment } from './LadderMatchComment';
import { StoreGetState } from '@store/configureStore';

type RootState = ReturnType<StoreGetState>


const ladderMatchCommentAdapter = createEntityAdapter<ILadderMatchComment>();
export const initialLadderMatchCommentState = ladderMatchCommentAdapter.getInitialState();

const ladderMatchCommentSlice = createSlice({
  name: 'ladderMatchCommentSlice',
  initialState: initialLadderMatchCommentState,
  reducers: {},
});

export default ladderMatchCommentSlice.reducer;

export const {
  selectAll: selectAllLadderMatchComments,
  selectById: selectLadderMatchCommentById,
  selectIds: selectLadderMatchCommentsIds
} = ladderMatchCommentAdapter.getSelectors((state: RootState) => state.ladderMatchComment);