import { api } from '@features';

import { ILadderDispute } from './LadderDispute';

export const ladderDisputeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDisputesList: builder.query<ILadderDispute[], void>({
      query: () => {
        return {
          method: 'GET',
          url: `/dispute`,
        };
      },
      providesTags: (result = []) => [
        ...result.map(({ _id }: Partial<ILadderDispute>) => ({ type: 'Dispute', _id } as const)),
        { type: 'Dispute' as const, _id: 'LIST' },
      ],
      transformResponse: (response: { disputes: ILadderDispute[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.disputes;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    resolveDispute: builder.mutation<ILadderDispute, { disputeId: string; winner: string; }>({
      query: ({disputeId, winner}) => {
        return {
          method: 'POST',
          url: `/dispute/${disputeId}`,
          body: { winner }
        };
      },
      invalidatesTags: ['Dispute'],
      transformResponse: (response: { dispute: ILadderDispute, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.dispute;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
  })
});

export const { useGetDisputesListQuery, useResolveDisputeMutation } = ladderDisputeApi;