import { api } from '@features';

import { ELadderChallengeState, ILadderChallenge } from './LadderChallenge';

export const ladderChallengeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteChallengeById: builder.mutation<boolean, string>({
      query: (challengeId: string) => {
        return {
          method: 'DELETE',
          url: `/challenge/${challengeId}`,
        };
      },
      invalidatesTags: ['Challenge'],
      transformResponse: (response: { success: boolean }, meta, arg) => {
        if (response.success) {
          return response.success;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }      
    }),
    getChallengeById: builder.query<ILadderChallenge, string>({
      query: (challengeId: string) => {
        return {
          method: 'GET',
          url: `/challenge/${challengeId}`,
        };
      },
      providesTags: (_result, _err, _id) => [{ type: 'Challenge', _id }],
      transformResponse: (response: { challenge: ILadderChallenge, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.challenge;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    }),
    getCurrentUserChallenges: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: `/challenge/me`,
        }
      },
      providesTags: ['Challenge'],
      transformResponse: (response: { challenges: ILadderChallenge[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.challenges;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    getChallengesList: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: `/challenge`,
        }
      },
      providesTags: ['Challenge'],
      transformResponse: (response: { challenges: ILadderChallenge[], success: boolean }, meta, arg) => {
        if (response.success) {
          return response.challenges;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response;
      }
    }),
    updateChallengeStatus: builder.mutation<ILadderChallenge, {
      acceptedState: ELadderChallengeState;
      challengeId: string;
      teamChallengedPick?: string;
      teamChallengedPreferenceOne?: string;
      teamChallengedPreferenceTwo?: string;
      teamChallengedPreferenceThree?: string;
    }>({
      query: ({ acceptedState, challengeId, teamChallengedPick, teamChallengedPreferenceOne, teamChallengedPreferenceTwo, teamChallengedPreferenceThree }) => {
        return {
          method: 'PUT',
          url: `/challenge/${challengeId}`,
          body: {
            acceptedState,
            teamChallengedPick,
            teamChallengedPreferenceOne,
            teamChallengedPreferenceTwo,
            teamChallengedPreferenceThree
          },
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [{ type: 'Challenge' }],
      transformResponse: (response: { challenge: ILadderChallenge, success: boolean }, meta, arg) => {
        if (response.success) {
          return response.challenge;
        }
        return Promise.reject('Unauthorized');
      },
      transformErrorResponse: (response, meta, arg) => {
        if (response.status === 401) {
          return 'Unauthorized';
        }
        return response.data;
      }
    })
  }),
});

export const {
  useDeleteChallengeByIdMutation,
  useGetChallengeByIdQuery,
  useGetChallengesListQuery,
  useGetCurrentUserChallengesQuery,
  useUpdateChallengeStatusMutation
} = ladderChallengeApi;