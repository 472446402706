import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';

import { ILadder } from './Ladder';
import { StoreGetState } from '@store/configureStore';
import { ladderApi } from './ladderApi';

type RootState = ReturnType<StoreGetState>


export const ladderAdapter = createEntityAdapter<ILadder>({
  selectId: (ladder) => ladder._id,
});
export const initialLadderState = ladderAdapter.getInitialState();

const ladderSlice = createSlice({
  name: 'ladder',
  initialState: initialLadderState,
  reducers: {},
});

export default ladderSlice.reducer;

export const selectAllLadders = createSelector(
  ladderApi.endpoints.getLadders.select({ limit: 1000, populate: 'entries,' }),
  (state: RootState) => state.ladder.entities,
  (state, ladders) => ladders,
);

export const selectLadderByName = createSelector(
  ladderApi.endpoints.getLadders.select({ limit: 1000, populate: 'entries,' }),
  (state: RootState, ladderName: string) => ladderName,
  (state, ladderName) => {
    // @ts-ignore
    return state.data?.find((ladder) => ladder.name === ladderName) ?? [];
  }
);

export const selectLadderByNameOrId = createSelector(
  ladderApi.endpoints.getLadders.select({ limit: 1000, populate: 'entries,' }),
  (state: RootState, ladderNameOrId: string) => ladderNameOrId,
  (state, ladderNameOrId) => {
    // @ts-ignore
    return state.data?.find((ladder) => ladder.name === ladderNameOrId || ladder._id === ladderNameOrId) ?? [];
  }
);
